import FamilyMember from "@/models/FamilyMember";

export default class User {
    device_name: string;
    id: number;
    mobile: string;
    accessToken?: string;
    first_name?: string;
    last_name?: string;
    children?: FamilyMember[];
    parents?: FamilyMember[];
    usedServices?: { [key: string]: number };

    constructor({
                    id = 0,
                    mobile = '',
                    accessToken = '',
                    first_name = '',
                    last_name = '',
                    children = [],
                    parents = [],
                    usedServices = {}
                } = {}) {
        this.device_name = 'VUE';
        this.id = id;
        this.mobile = mobile;
        this.accessToken = accessToken;
        this.first_name = first_name;
        this.last_name = last_name;
        this.children = children.map(child => new FamilyMember(child));
        this.parents = parents.map(parent => new FamilyMember(parent));
        this.usedServices = usedServices;
    }

    update(data: Partial<User>) {
        if (data.first_name !== undefined) {
            this.first_name = data.first_name;
        }
        if (data.last_name !== undefined) {
            this.last_name = data.last_name;
        }
        if (data.mobile !== undefined) {
            this.mobile = data.mobile;
        }
        if (data.accessToken !== undefined) {
            this.accessToken = data.accessToken;
        }
        if (data.children !== undefined) {
            this.children = data.children;
        }
        if (data.parents !== undefined) {
            this.parents = data.parents;
        }
        if (data.usedServices !== undefined) {
            this.usedServices = data.usedServices;
        }
    }
}
