import { DirectiveBinding } from 'vue';

function formatNumber(value: number | null | undefined): string {
    if (value == null) {
        return '';
    }
    return value.toLocaleString();
}

const formatPrice = {
    beforeMount(el: HTMLElement, binding: DirectiveBinding) {
        el.textContent = formatNumber(binding.value / 10);
    },
    updated(el: HTMLElement, binding: DirectiveBinding) {
        el.textContent = formatNumber(binding.value / 10);
    }
};

export default formatPrice;
