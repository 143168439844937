import { Module } from 'vuex';
import { AlertState, RootState } from "@/store/types";
import { useToast } from "vue-toastification";

const toast = useToast();

const state: AlertState = {
    error: null,
};

const actions = {
    setAlert(_: any, payload: { message: string; type: 'success' | 'error' | 'warning' | 'info'; showToast?: boolean }) {
        if (payload.showToast !== false) {
            toast[payload.type](payload.message);
        }
    },
};

const alertModule: Module<AlertState, RootState> = {
    namespaced: true,
    state,
    actions,
};

export default alertModule;