import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import User from "@/models/User";
import UserData from "@/models/UserData";

class AuthService {
    async init() {
        const { data } = await axiosInstance.get('/api/auth/init/', { headers: authHeader() });
        return data;
    }

    async logout() {
        const { data } = await axiosInstance.post('/api/auth/logout/', { headers: authHeader() });
        return data;
    }

    async sendOtp(mobile: string) {
        const { data } = await axiosInstance.post('/api/auth/send-otp/', { mobile });
        return data;
    }

    async verifyOtp(mobile: string, otp: string) {
        const { data } = await axiosInstance.post('/api/auth/verify-otp/', { mobile, otp });
        return data;
    }

    async updateUser(payload: Partial<User>) {
        const { data } = await axiosInstance.patch('/api/auth/user/update/', payload, { headers: authHeader() });
        return data;
    }

    async addUserData(payload: Partial<UserData>) {
        const { data } = await axiosInstance.post('/api/auth/user-data/create/', payload, { headers: authHeader() });
        return data;
    }

    async latestUserData() {
        const { data } = await axiosInstance.get('/api/auth/user-data/latest/', { headers: authHeader() });
        return data;
    }
}

export default new AuthService();