import { Commit, Module } from 'vuex';
import { LoaderState, RootState } from "@/store/types";

const state: LoaderState = {
    isLoading: false,
    componentLoading: {},
};

const mutations = {
    SET_LOADING(state: LoaderState, { isLoading, component }: { isLoading: boolean; component?: string }) {
        if (component) {
            state.componentLoading = {
                ...state.componentLoading,
                [component]: isLoading,
            };
        } else {
            state.isLoading = isLoading;
        }
    },
};

const actions = {
    async setLoading({ commit }: { commit: Commit }, { isLoading, component }: { isLoading: boolean; component?: string }): Promise<void> {
        commit('SET_LOADING', { isLoading, component });
        return Promise.resolve();
    },
};

const getters = {
    isLoading: (state: LoaderState) => state.isLoading,
    isComponentLoading: (state: LoaderState) => (component: string) => state.componentLoading[component] || false,
};

const loaderModule: Module<LoaderState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};

export default loaderModule;
