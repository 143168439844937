import {ActionContext, Module} from 'vuex';
import {RootState, ShopState} from '@/store/types';
import {handleError, handleSuccess} from '@/utils/alertHandler';
import Product from "@/models/shop/Product";
import Cart from "@/models/shop/Cart";
import shopService from "@/services/shopService";
import CartItem from "@/models/shop/CartItem";
import analyticsService from "@/services/analyticsService";
import CartData from "@/models/shop/CartData";
import {PaymentMethod, PaymentResult} from "@/types/payment";

const state: ShopState = {
    error: null,
    loadedProduct: new Product(),
    cart: new Cart(),
    isCartFetched: false,
    selectedAddressId: null,
    cartData: {},
    paymentResult: null,
    subtotal: 0,
    tax: 0,
    shippingCost: 0,
    couponDiscount: 0,
    total: 0,
    promoCode: null,
};

interface LatestCartDataResponse {
    [key: string]: string;
}

const getters = {
    loadedProduct: (state: ShopState) => state.loadedProduct,
    cartItems: (state: ShopState) => state.cart.items,
    cartTotal: (state: ShopState) => state.cart.total,
    isCartFetched: (state: ShopState) => state.isCartFetched,
    selectedAddressId: (state: ShopState) => state.selectedAddressId,
    subtotal: (state: ShopState) => state.subtotal,
    tax: (state: ShopState) => state.tax,
    shippingCost: (state: ShopState) => state.shippingCost,
    couponDiscount: (state: ShopState) => state.couponDiscount,
    total: (state: ShopState) => state.total,
};

const mutations = {
    SET_LOADED_PRODUCT(state: ShopState, product: Product) {
        state.loadedProduct = product;
    },
    SET_ERROR(state: ShopState, error: string) {
        state.error = error;
    },
    SET_CART(state: ShopState, cart: Cart) {
        state.cart = cart;
        state.isCartFetched = true;
    },
    ADD_TO_CART(state: ShopState, item: CartItem) {
        const existingItem = state.cart.items.find(i => i.variety.id === item.variety.id);
        if (existingItem) {
            existingItem.quantity += item.quantity;
        } else {
            state.cart.items.push(item);
        }
    },
    REMOVE_FROM_CART(state: ShopState, varietyId: number) {
        state.cart.items = state.cart.items.filter(item => item.variety.id !== varietyId);
    },
    UPDATE_CART_ITEM(state: ShopState, item: CartItem) {
        const existingItem = state.cart.items.find(i => i.variety.id === item.variety.id);
        if (existingItem) {
            existingItem.quantity = item.quantity;
        }
    },
    CLEAR_CART(state: ShopState) {
        state.cart.items = [];
    },
    SET_SELECTED_ADDRESS(state: ShopState, addressId: number) {
        state.selectedAddressId = addressId;
        state.cartData['selected_address_id'] = addressId;
    },
    ADD_CART_DATA(state: ShopState, data: CartData) {
        state.cartData[data.data_key] = data.data_value;
        if (data.data_key === 'selected_address_id') {
            state.selectedAddressId = Number(data.data_value);
        }
    },
    SET_PAYMENT_RESULT(state: ShopState, result: PaymentResult) {
        state.paymentResult = result;
    },
    SET_PRICING_DETAILS(state: ShopState, pricingDetails: any) {
        state.subtotal = parseFloat(pricingDetails.subtotal);
        state.tax = parseFloat(pricingDetails.tax);
        state.shippingCost = parseFloat(pricingDetails.shipping_cost);
        state.couponDiscount = parseFloat(pricingDetails.discount);
        state.total = parseFloat(pricingDetails.total);
    },
    SET_PROMO_CODE(state: ShopState, promoCode: string) {
        state.promoCode = promoCode;
    },
};

const actions = {
    async fetchProduct({ commit, dispatch }: ActionContext<ShopState, unknown>, productId: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProduct' }, { root: true });
        try {
            const product = await shopService.fetchProduct(productId);
            commit('SET_LOADED_PRODUCT', product);
        } catch (error) {
            await handleError(error, true, { action: 'shop/fetchProduct', productId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProduct' }, { root: true });
        }
    },
    async fetchCart({ commit, dispatch }: ActionContext<ShopState, RootState>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateCart' }, { root: true });
        try {
            const cart = await shopService.fetchCart();
            commit('SET_CART', cart);
        } catch (error) {
            await handleError(error, true, { action: 'shop/fetchCart' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateCart' }, { root: true });
        }
    },
    async addToCart({ commit, dispatch }: ActionContext<ShopState, RootState>, { varietyId, quantity }: { varietyId: number, quantity: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateCart' }, { root: true });
        try {
            const cartItem = await shopService.addToCart(varietyId, quantity);
            commit('ADD_TO_CART', new CartItem(cartItem));
            await handleSuccess('shop.itemAddedToCart');
        } catch (error) {
            await handleError(error, true, { action: 'shop/addToCart', varietyId, quantity });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('shop_added_to_cart', {
                varietyId: varietyId,
                quantity: quantity
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateCart' }, { root: true });
        }
    },
    async removeFromCart({ commit, dispatch }: ActionContext<ShopState, RootState>, varietyId: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateCart' }, { root: true });
        try {
            await shopService.removeFromCart(varietyId);
            commit('REMOVE_FROM_CART', varietyId);
            await handleSuccess('shop.itemRemovedFromCart');
        } catch (error) {
            await handleError(error, true, { action: 'shop/removeFromCart', varietyId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('shop_removed_from_cart', {
                varietyId: varietyId,
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateCart' }, { root: true });
        }
    },
    async updateCartItem({ commit, dispatch }: ActionContext<ShopState, RootState>, { varietyId, quantity }: { varietyId: number, quantity: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateCart' }, { root: true });
        try {
            const data = await shopService.updateCartItem(varietyId, quantity);
            commit('UPDATE_CART_ITEM', { varietyId, quantity: data.quantity });
            await handleSuccess('shop.updatedCartItem');
        } catch (error) {
            await handleError(error, true, { action: 'shop/updateCartItem', varietyId, quantity });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('shop_updated_cart', {
                varietyId: varietyId,
                quantity: quantity
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateCart' }, { root: true });
        }
    },
    async clearCart({ commit, dispatch }: ActionContext<ShopState, RootState>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateCart' }, { root: true });
        try {
            await shopService.clearCart();
            commit('CLEAR_CART');
            await handleSuccess('shop.clearedCart');
        } catch (error) {
            await handleError(error, true, { action: 'shop/clearCart' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('shop_cleared_cart');
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateCart' }, { root: true });
        }
    },
    async addCartData({ commit, dispatch, state }: ActionContext<ShopState, unknown>, payload: Partial<CartData>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchCartData' }, { root: true });
        try {
            const response = await shopService.addCartData(payload);
            commit('ADD_CART_DATA', response);
        } catch (error) {
            await handleError(error, true, { action: 'auth/addCartData', payload });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchCartData' }, { root: true });
        }
    },
    async latestCartData({ commit, dispatch }: ActionContext<ShopState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchCartData' }, { root: true });
        try {
            const response = await shopService.latestCartData();
            const cartData = response as LatestCartDataResponse;
            for (const [data_key, data_value] of Object.entries(cartData)) {
                commit('ADD_CART_DATA', new CartData({ data_key, data_value }));
            }
        } catch (error) {
            await handleError(error, true, { action: 'shop/latestCartData' });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchCartData' }, { root: true });
        }
    },
    async placeOrder({ commit, dispatch }: ActionContext<ShopState, RootState>,
                     { addressId, paymentMethod, promoCode, additionalData }:
                         { addressId: number, paymentMethod: PaymentMethod, promoCode: string | null, additionalData?: any }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'placeOrder' }, { root: true });
        try {
            const orderData = {
                address_id: addressId,
                payment_method: paymentMethod,
                promo_code: promoCode,
                ...additionalData
            };

            const result = await shopService.placeOrder(orderData);
            commit('SET_PAYMENT_RESULT', result);
            commit('CLEAR_CART');
            await handleSuccess('shop.orderPlaced');
            analyticsService.track('shop_order_placed', {
                addressId,
                paymentMethod,
                ...additionalData
            });

            return result;
        } catch (error) {
            await handleError(error, true, { action: 'shop/placeOrder', addressId, paymentMethod });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
            throw error;
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'placeOrder' }, { root: true });
        }
    },

    async checkOrderStatus({ commit, dispatch }: ActionContext<ShopState, RootState>, orderReference: string) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'checkOrderStatus' }, { root: true });
        try {
            return await shopService.checkOrderStatus(orderReference);
        } catch (error) {
            await handleError(error, true, { action: 'shop/checkOrderStatus', orderReference });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
            throw error;
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'checkOrderStatus' }, { root: true });
        }
    },

    async applyPromoCode({ commit, dispatch }: ActionContext<ShopState, RootState>, promoCode: string) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'applyPromoCode' }, { root: true });
        try {
            const discount = await shopService.applyPromoCode(promoCode);
            commit('SET_COUPON_DISCOUNT', discount);
            commit('SET_PROMO_CODE', promoCode);
        } catch (error) {
            await handleError(error, true, { action: 'shop/applyPromoCode', promoCode });
            throw error;
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'applyPromoCode' }, { root: true });
        }
    },
    async fetchPricingDetails({ commit, dispatch }: ActionContext<ShopState, RootState>, { addressId, promoCode }: { addressId: number, promoCode: string }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchPricingDetails' }, { root: true });
        try {
            const pricingDetails = await shopService.getPricingDetails(addressId, promoCode);
            commit('SET_PRICING_DETAILS', pricingDetails);
        } catch (error) {
            await handleError(error, true, { action: 'shop/fetchPricingDetails', addressId, promoCode });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchPricingDetails' }, { root: true });
        }
    },
};

const shopModule: Module<ShopState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default shopModule;
