import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import { Address } from "@/models/Address";
import { State } from "@/models/State";
import { City } from "@/models/City";

class AddressService {
    // Fetch all addresses for the authenticated user
    async fetchAddresses() {
        const { data } = await axiosInstance.get('/api/auth/addresses/', { headers: authHeader() });
        return data.map((address: any) => new Address(
            address.id,
            address.name,
            address.mobile,
            address.phone_number,
            new State(address.state.id, address.state.name, address.state.code),
            new City(address.city.id, address.city.name, address.city.code, address.city.is_capital, null),
            address.address,
            address.postal_code,
            address.is_default
        ));
    }

    // Fetch all states
    async fetchStates() {
        const { data } = await axiosInstance.get('/api/auth/states/', { headers: authHeader() });
        return data.map((state: any) => new State(state.id, state.name, state.code));
    }

    // Fetch all cities for a specific state
    async fetchCities(stateId: number) {
        const { data } = await axiosInstance.get(`/api/auth/states/${stateId}/cities/`, { headers: authHeader() });
        return data.map((city: any) => new City(city.id, city.name, city.code, city.is_capital, null));
    }

    // Create a new address
    async createAddress(payload: Partial<Address>) {
        const { data } = await axiosInstance.post(
            '/api/auth/addresses/',
            {
                // Explicitly include only necessary fields
                name: payload.name,
                mobile: payload.mobile,
                phone_number: payload.phone_number,
                state_id: payload.state_id || (payload.state ? payload.state.id : null),
                city_id: payload.city_id || (payload.city ? payload.city.id : null),
                address: payload.address,
                postal_code: payload.postal_code,
                is_default: payload.isDefault,
            },
            { headers: authHeader() }
        );

        return new Address(
            data.id,
            data.name,
            data.mobile,
            data.phone_number,
            data.state ? new State(data.state.id, data.state.name, data.state.code) : null,
            data.city ? new City(data.city.id, data.city.name, data.city.code, data.city.is_capital, null) : null,
            data.address,
            data.postal_code,
            data.is_default
        );
    }

// Update an existing address
    async updateAddress(payload: Partial<Address>) {
        const { data } = await axiosInstance.put(
            `/api/auth/addresses/${payload.id}/`,
            {
                // Explicitly include only necessary fields
                name: payload.name,
                mobile: payload.mobile,
                phone_number: payload.phone_number,
                state_id: payload.state_id || (payload.state ? payload.state.id : null),
                city_id: payload.city_id || (payload.city ? payload.city.id : null),
                address: payload.address,
                postal_code: payload.postal_code,
                is_default: payload.isDefault,
            },
            { headers: authHeader() }
        );

        return new Address(
            data.id,
            data.name,
            data.mobile,
            data.phone_number,
            data.state ? new State(data.state.id, data.state.name, data.state.code) : null,
            data.city ? new City(data.city.id, data.city.name, data.city.code, data.city.is_capital, null) : null,
            data.address,
            data.postal_code,
            data.is_default
        );
    }

    // Delete an address by ID
    async deleteAddress(id: number) {
        await axiosInstance.delete(`/api/auth/addresses/${id}/`, { headers: authHeader() });
    }
}

export default new AddressService();