<template>
  <curved-background>
    <div class="relative z-10 p-4">
      <nav class="bg-background border-primary">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://flowbite.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="/images/logo.png" class="h-20" alt="{{ $t('brand.name') }}" />
            <span class="self-center text-2xl font-semibold whitespace-nowrap text-text">{{ $t('brand.name') }}</span>
          </a>
          <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-text-light rounded-lg md:hidden hover:bg-accentExtra-lightest focus:outline-none focus:ring-2 focus:ring-primary">
            <span class="sr-only">Open main menu</span>
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-primary rounded-lg bg-background md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
              <li>
                <a :href="$t('home.navbar.home.link')" class="block py-2 px-3 text-text-light bg-secondary rounded md:bg-transparent md:text-secondary-dark md:p-0" aria-current="page">{{ $t('home.navbar.home.text') }}</a>
              </li>
              <li>
                <a :href="$t('home.navbar.aboutUs.link')" class="block py-2 px-3 text-text rounded hover:bg-accentExtra-lightest md:hover:bg-transparent md:border-0 md:hover:text-accent md:p-0">{{ $t('home.navbar.aboutUs.text') }}</a>
              </li>
              <li>
                <a :href="$t('home.navbar.contactUs.link')" class="block py-2 px-3 text-text rounded hover:bg-accentExtra-lightest md:hover:bg-transparent md:border-0 md:hover:text-accent md:p-0">{{ $t('home.navbar.contactUs.text') }}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="antialiased">

        <section class="text-text body-font">
          <div class="container mx-auto flex md:px-24 md:py-10 md:flex-row flex-col items-center">
            <div class="lg:flex-grow mt-5 md:mt-0 md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start ltr:md:text-left rtl:md:text-right mb-16 md:mb-0 items-center text-center">
              <h1 class="text-2xl font-extrabold leading-9 tracking-tight mb-3 text-text sm:text-4xl sm:leading-10 md:text-5xl md:leading-normal">
                {{ $t('brand.slogan') }}
              </h1>
              <p class="mb-8 ltr:md:pl-0 rtl:md:pr-0 pl-2 pr-2 leading-relaxed text-text-light">
                {{ $t('brand.mission') }}
              </p>
              <div class="flex justify-center">
                <router-link :to="{ name: 'TurnFuture' }" class="inline-flex text-text bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary-dark rounded text-lg">
                  {{ $t('home.letsStart') }}
                </router-link>
              </div>
            </div>
            <div class="lg:max-w-lg lg:w-full mb-5 md:mb-0 md:w-1/2 w-3/6">
              <img class="object-cover object-center rounded" alt="{{ $t('brand.name') }}" src="/images/homepage/hero_section.png">
            </div>
          </div>
        </section>
        <section class="">
          <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <img class="rounded-xl" src="/images/homepage/story_query.png" alt="Create new Story">
            <div class="mt-4 md:mt-0">
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-text">
                {{ $t('home.askAnything') }}
              </h2>
              <div class="text-text">
                <h2 class="text-2xl font-bold text-blue-800 mb-4 text-center">{{ $t('home.smartSearch') }}</h2>
                <p class="text-text-light mb-4">{{ $t('home.smartSearchDescription') }}</p>
                <ul class="space-y-2 mb-4">
                  <li class="flex items-center justify-start">
                    <span class="text-yellow-500 text-xl">🌟</span>
                    <span class="rtl:mr-2 ltr:ml-2">{{ $t('home.smartSearchFeatures[0].description') }}</span>
                  </li>
                  <li class="flex items-center justify-start">
                    <span class="text-yellow-500 text-xl">🌟</span>
                    <span class="rtl:mr-2 ltr:ml-2">{{ $t('home.smartSearchFeatures[1].description') }}</span>
                  </li>
                  <li class="flex items-center justify-start">
                    <span class="text-yellow-500 text-xl">🌟</span>
                    <span class="rtl:mr-2 ltr:ml-2">{{ $t('home.smartSearchFeatures[2].description') }}</span>
                  </li>
                  <li class="flex items-center justify-start">
                    <span class="text-yellow-500 text-xl">🌟</span>
                    <span class="rtl:mr-2 ltr:ml-2">{{ $t('home.smartSearchFeatures[3].description') }}</span>
                  </li>
                </ul>
                <p class="mb-4 ltr:text-left rtl:text-right">{{ $t('home.smartSearchExamples') }}</p>
                <p class="ltr:text-left rtl:text-right mb-4">{{ $t('home.smartSearchExperience') }}</p>
              </div>
              <router-link :to="{ name: 'createUserStory' }" class="inline-flex items-center text-text bg-primary hover:bg-primary-dark focus:ring-4 focus:ring-primary-light font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                {{ $t('home.trySmartSearch') }}
              </router-link>
            </div>
          </div>
        </section>
        <section class="">
          <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
            <div class="mt-4 md:mt-0">
              <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-text">
                {{ $t('common.turnFuture') }}
              </h2>
              <p class="mb-6 font-light text-text-light md:text-lg">
                {{ $t('home.turnFutureDescription') }}
              </p>
              <router-link :to="{ name: 'TurnFuture' }" class="inline-flex items-center text-text bg-primary hover:bg-primary-dark focus:ring-4 focus:ring-primary-light font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                {{ $t('home.turnFutureCTA') }}
              </router-link>
            </div>
            <img class="rounded-xl" src="/images/homepage/turn_into_the_future.png" alt="Turn into the future">
          </div>
        </section>
      </div>
    </div>
    <div class="flex justify-center">
      <a referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=526444&Code=Vv1pil9MFGQnLMsvnhaeGUzJ1sDi98G7'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=526444&Code=Vv1pil9MFGQnLMsvnhaeGUzJ1sDi98G7' alt='' style='cursor:pointer' code='Vv1pil9MFGQnLMsvnhaeGUzJ1sDi98G7'></a>
    </div>

  </curved-background>
</template>

<script lang="ts">
import 'flowbite/dist/flowbite.css';
import 'flowbite';
import {initFlowbite} from 'flowbite'
import { defineComponent } from 'vue';
import CurvedBackground from "@/layouts/CurvedBackground.vue";


export default defineComponent({
  name: 'HomeView',
  components: {
    CurvedBackground
  },
  mounted() {
    initFlowbite();
  }
});
</script>
