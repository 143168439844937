import { State } from './State';

export class City {
    id: number;
    name: string;
    code: number;
    is_capital: boolean;
    state: State | null;

    constructor(id: number, name: string, code: number, is_capital: boolean, state: State | null) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.is_capital = is_capital;
        this.state = state;
    }
}
