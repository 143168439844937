import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import UserStory from "@/models/UserStory";

class UserStoryService {
    async fetchUserStories() {
        const { data } = await axiosInstance.get('/api/userstory/stories/list/', { headers: authHeader() });
        return data.map((item: any) => new UserStory(item));
    }
    async addUserStory(query: string) {
        const { data } = await axiosInstance.post(
            '/api/userstory/stories/',
            { query: query },
            { headers: authHeader() }
        );
        return new UserStory(data);
    }
    async fetchUserStory(userStoryId: number) {
        const { data } = await axiosInstance.get(`/api/userstory/stories/${userStoryId}/`, { headers: authHeader() });
        return new UserStory(data);
    }
}

export default new UserStoryService();
