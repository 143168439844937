import {AnalyticsService} from "@/services/analyticsService";

interface ErrorLog {
    timestamp: string;
    message: string;
    stack?: string;
    additionalInfo?: any;
}

const errorLogs: ErrorLog[] = [];

export async function logError(error: unknown, additionalInfo?: any): Promise<void> {
    const errorLog: ErrorLog = {
        timestamp: new Date().toISOString(),
        message: error instanceof Error ? error.message : String(error),
        stack: error instanceof Error ? error.stack : undefined,
        additionalInfo
    };

    errorLogs.push(errorLog);
    console.error('Error logged:', errorLog);

    try {
        const analytics = new AnalyticsService();
        analytics.trackError(error, additionalInfo);
    } catch (sendError) {
        console.error('Failed to send error to server:', sendError);
    }
}

export function getErrorLogs(): ErrorLog[] {
    return errorLogs;
}