export default class FamilyMember {
    id: number;
    name: string;
    last_name: string;
    role: string;
    gender: string;
    multiple: number;
    nth_child: number;
    birthdate: string;
    image_url: string;
    current_milestone: string;
    usedServices: { [key: string]: number };

    constructor({
                    id = 0,
                    name = '',
                    last_name = '',
                    role = '',
                    gender = '',
                    multiple = 1,
                    nth_child = 1,
                    birthdate = '',
                    image_url = '',
                    current_milestone = '',
                    usedServices = {}
                } = {}) {
        this.id = id;
        this.name = name;
        this.last_name = last_name;
        this.role = role;
        this.gender = gender !== '' ? gender : role === 'father' ? 'boy' : role === 'mother' ? 'girl' : '';
        this.multiple = multiple;
        this.nth_child = nth_child;
        this.birthdate = birthdate;
        this.image_url = image_url;
        this.current_milestone = current_milestone;
        this.usedServices = usedServices;
    }

    isBorn() {
        if (this.role !== "child") {
            return true;
        }
        if (!this.birthdate) {
            return false;
        }
        const birthdate = new Date(this.birthdate);
        const today = new Date();
        return birthdate <= today;
    }
}
