import Path from "@/models/Path";

export default class ProductPath {
    id: number;
    path: Path;
    percent: number;
    why: string;

    constructor({id = 0, path = {}, percent = 0, why = ''} = {}) {
        this.id = id;
        this.path = new Path(path);
        this.percent = percent;
        this.why = why;
    }
}