<template>
  <div>
    <!-- Initial Loading Screen -->
    <div v-if="isInitialLoading" class="fixed inset-0 flex items-center justify-center bg-background z-50">
      <div class="text-center">
        <img src="/images/logo.png" alt="Loading" class="w-40 h-40 mx-auto mb-8">
        <div class="flex justify-center">
          <bouncing-dots-icon></bouncing-dots-icon>
        </div>
      </div>
    </div>

    <!-- Default Loader for other loading states -->
    <div v-else-if="isLoading" class="loader">
      <bouncing-dots-icon></bouncing-dots-icon>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import BouncingDotsIcon from "@/components/Layouts/BouncingDotsIcon.vue";

export default defineComponent({
  name: 'AppLoader',
  components: {BouncingDotsIcon},
  setup() {
    const store = useStore();
    const isInitialLoading = computed(() => store.getters['loader/isComponentLoading']('loadingScreen'));
    const isLoading = computed(() => store.getters['loader/isLoading']);

    return {
      isInitialLoading,
      isLoading,
    };
  },
});
</script>

<style scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>