import ProductPath from "@/models/shop/ProductPath";

export default class UserStory {
    id: number;
    query: string;
    title: string;
    story: string;
    summary: string;
    paths: ProductPath[];

    constructor({id = 0,query = '', title = '', story = '', summary = '', paths = []} = {}) {
        this.id = id;
        this.query = query;
        this.title = title;
        this.story = story;
        this.summary = summary;
        this.paths = paths.map(productPath => new ProductPath(productPath));
    }
}
