import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';
import {RootState} from '@/store/types';
import {getCurrentInstance} from "vue";
import {getUserCookie} from "@/utils/cookieUtils";
import {i18n} from "@/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      titleKey: 'home'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/LoginView.vue'),
    meta: {
      titleKey: 'login'
    }
  },
  {
    path: '/complete-user-data',
    name: 'CompleteUserData',
    component: () => import('../views/Login/CompleteUserDataView.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'completeUserData'
    }
  },
  {
    path: '/turn-future',
    name: 'TurnFuture',
    component: () => import('../views/Services/TurnFutureView.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'turnFuture'
    }
  },
  {
    path: '/my/room',
    name: 'MyRoom',
    component: () => import('../views/Shop/MyRoom.vue'),
    meta: {
      requiresAuth: true,
      titleKey: 'myRoom',
      requiresCompleteUserData: true,
    }
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    component: () => import('../views/Shop/ProductView.vue'),
    props: true,
    meta: {
      titleKey: 'productDetails'
    }
  },
  {
    path: '/shipping',
    name: 'ShippingView',
    component: () => import('../views/Shop/ShippingView.vue'),
    props: true,
    meta: {
      titleKey: 'shippingAddress'
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Shop/CheckoutView.vue'),
    props: true,
    meta: {
      titleKey: 'checkout'
    }
  },
  {
    path: '/order-confirmation/:reference',
    name: 'OrderConfirmation',
    component: () => import('../views/Shop/OrderConfirmationView.vue'),
    props: true,
    meta: {
      titleKey: 'checkout'
    }
  },
  {
    path: '/stories',
    name: 'createUserStory',
    component: () => import('../views/UserStories/UserStoryForm.vue'),
    props: true,
    meta: {
      requiresCompleteUserData: true,
      requiresAuth: true,
      titleKey: 'createUserStory'
    }
  },
  {
    path: '/stories/:id',
    name: 'UserStoryPage',
    component: () => import('../views/UserStories/UserStoryPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      titleKey: 'userStoryPage'
    }
  },
  {
    path: '/telegram/verify/:token',
    name: 'TelegramVerification',
    component: () => import('../views/Telegram/VerificationPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      titleKey: 'userStoryPage'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
  const storeState = store.state as RootState;

  if (!storeState.auth.user) {
    // Check both localStorage and cookie
    const localStorageUser = JSON.parse(localStorage.getItem('user') || 'null');
    const cookieUser = getUserCookie();

    if (localStorageUser || cookieUser) {
      // If user data exists in either localStorage or cookie, dispatch init
      try {
        await store.dispatch('auth/init');
      } catch (error) {
        console.error('Store initialization failed:', error);
      }
    }
  }

  // Check if the route requires complete user data
  if (to.matched.some(record => record.meta.requiresCompleteUserData)) {
    const user = store.getters['auth/user'];

    // Check if user first name is filled and at least one child is added
    const hasFirstName = user && typeof user.first_name === 'string' && user.first_name.trim() !== '';
    const hasChild = user && user.children && user.children.length > 0;

    if (!hasFirstName || !hasChild) {
      next({
        name: 'CompleteUserData',
        query: { redirect: to.fullPath }
      });
      return;
    }
  }

  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isLoggedIn']) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      });
      return;
    }
  }

  // Handle login page access when already logged in
  if (to.name === 'Login' && store.getters['auth/isLoggedIn']) {
    const redirect = to.query.redirect || '/';
    next(redirect as string);
  } else {
    next();
  }
});

router.afterEach((to) => {
  const instance = getCurrentInstance();
  if (instance) {
    const { $gtag } = instance.appContext.config.globalProperties;
    if ($gtag) {
      $gtag.pageview({
        page_path: to.fullPath,
        page_title: to.name ? to.name.toString() : '',
        page_location: window.location.href
      });
    }
  }

  if (to.meta.titleKey) {
    const titleKey = to.meta.titleKey as string;
    document.title = i18n.global.t("router." + titleKey);
  }
});

export default router