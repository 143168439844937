<template>
  <div :dir="direction">
    <AppLoader />
    <router-view/>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader.vue';

export default {
  name: 'App',
  components: {
    AppLoader,
  },
  computed: {
    direction() {
      return process.env.VUE_APP_DIRECTION || 'ltr';
    }
  }
};
</script>

<style>
#app {
  text-align: center;
}
</style>
