import axiosInstance from './axiosInstance';
import authHeader from './authHeader';

class aiServicesService {
    async fetchEmpatheticMessage() {
        const { data } = await axiosInstance.get('/api/services/empathetic-message/', { headers: authHeader() });
        return data;
    }
    async fetchFutureImage(memberId: number) {
        const { data } = await axiosInstance.post(
            '/api/services/into-future/',
            { member_id: memberId },
            { headers: authHeader() }
        );
        return data;
    }
}

export default new aiServicesService();
