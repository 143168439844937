import { PluginOptions, TYPE } from "vue-toastification";

export const toastOptions: PluginOptions = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    toastClassName: "custom-toast",
    bodyClassName: ["custom-toast-body"],
    filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter(t => t.type === toast.type).length !== 0) {
            return false;
        }
        // Add custom class based on toast type
        toast.toastClassName = `custom-toast custom-toast-${toast.type}`;
        return toast;
    },
};
