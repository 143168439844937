export function loadFont(locale: string): void {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    if (locale === 'fa') {
        link.href = '/fonts/IRANYekan/yekan.css';
    } else {
        link.href = '/fonts/default.css';
    }
    document.head.appendChild(link);
}
