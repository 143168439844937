import { createStore } from 'vuex';
import auth from "@/store/modules/authModule";
import loader from "@/store/modules/loaderModule";
import alert from "@/store/modules/alertModule";
import family from "@/store/modules/familyModule";
import recommendation from "@/store/modules/recommendationModule";
import journey from "@/store/modules/journeyModule";
import userStory from "@/store/modules/userStoryModule";
import shop from "@/store/modules/shopModule";
import turnFuture from "@/store/modules/turnFutureModule";
import aiServices from "@/store/modules/aiServicesModule";
import telegram from "@/store/modules/telegramModule";
import address from "@/store/modules/addressModule";
import { RootState } from "./types";


const store = createStore<RootState>({
  modules: {
    auth,
    loader,
    alert,
    family,
    recommendation,
    journey,
    userStory,
    shop,
    turnFuture,
    aiServices,
    telegram,
    address,
  }
});

export default store;
