import axios from 'axios';
import Cookies from 'js-cookie';
import { getCsrfToken } from '@/utils/csrf';
import authHeader from './authHeader';

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL || '/api',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor to include the CSRF token in the request headers
axiosInstance.interceptors.request.use(request => {
    const token = getCsrfToken();
    if (token) {
        request.headers['X-CSRFToken'] = token;
    }
    return request;
}, error => {
    return Promise.reject(error);
});

// Interceptor to include the session ID in the request headers
axiosInstance.interceptors.request.use(request => {
    const sessionId = Cookies.get('sessionid');
    if (sessionId) {
        request.headers['Cookie'] = `sessionid=${sessionId}`;
    }
    return request;
}, error => {
    return Promise.reject(error);
});

// Interceptor to include the authorization header
axiosInstance.interceptors.request.use(request => {
    const headers = authHeader();
    if (headers.Authorization) {
        request.headers.Authorization = headers.Authorization;
    }
    return request;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;