export default class Path {
    id: number;
    key: string;
    title: string;
    target: string;

    constructor({id = 0,key = '', title = '', target = ''} = {}) {
        this.id = id;
        this.key = key;
        this.title = title;
        this.target = target;
    }
}
