import { i18n } from '@/i18n';
import store from '@/store';
import { logError as logErrorToSystem } from './errorLogger';

export async function handleError(error: unknown, showToast = true, additionalInfo?: any): Promise<void> {
    let message = i18n.global.t('alert.unexpectedError');
    if (error instanceof Error) {
        message = error.message;
    }

    // Log the error with additional info
    await logErrorToSystem(error, additionalInfo);

    // Show toast if needed
    if (showToast) {
        await store.dispatch('alert/setAlert', { message, type: 'error', showToast });
    }
}

export async function handleSuccess(messageKey: string, showToast = true): Promise<void> {
    const message = i18n.global.t(messageKey);
    await store.dispatch('alert/setAlert', { message, type: 'success', showToast });
}

export async function handleWarning(messageKey: string, showToast = true): Promise<void> {
    const message = i18n.global.t(messageKey);
    await store.dispatch('alert/setAlert', { message, type: 'warning', showToast });
}

export async function handleInfo(messageKey: string, showToast = true): Promise<void> {
    const message = i18n.global.t(messageKey);
    await store.dispatch('alert/setAlert', { message, type: 'info', showToast });
}

export async function logError(error: unknown, additionalInfo?: any): Promise<void> {
    await logErrorToSystem(error, additionalInfo);
}

export async function logSuccess(messageKey: string): Promise<void> {
    await handleSuccess(messageKey, false);
}

export async function logWarning(messageKey: string): Promise<void> {
    await handleWarning(messageKey, false);
}

export async function logInfo(messageKey: string): Promise<void> {
    await handleInfo(messageKey, false);
}