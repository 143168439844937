<template>
  <div :class="['loading-dots', classes]">
    <div class="dot text-text"></div>
    <div class="dot text-text"></div>
    <div class="dot text-text"></div>
  </div>
</template>

<script>
export default {
  name: "BouncingDotsIcon",
  props: {
    classes: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>
.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: currentColor;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out both;
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
</style>
