import { createI18n, I18n } from 'vue-i18n';

const loadedLanguages: { [key: string]: boolean } = {};

function setI18nLanguage(i18n: I18n, locale: string): string {
    i18n.global.locale = locale;
    const htmlElement = document.querySelector('html');
    if (htmlElement) {
        htmlElement.setAttribute('lang', locale);
    }
    return locale;
}

async function loadLocaleMessages(i18n: I18n, locale: string): Promise<string> {
    if (loadedLanguages[locale]) {
        return Promise.resolve(setI18nLanguage(i18n, locale));
    }
    const messages = await import(/* webpackChunkName: "locale-[request]" */ `./${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
    loadedLanguages[locale] = true;
    return setI18nLanguage(i18n, locale);
}

const i18n = createI18n({
    legacy: false,  // Enable Composition API mode by disabling legacy mode
    locale: 'fa', // Set default locale
    fallbackLocale: 'fa', // Set fallback locale
    globalInjection: true, // Optional: Allows direct use of `$t` in templates without `useI18n`
    messages: {} // Messages will be loaded dynamically
});

export { i18n, loadLocaleMessages };
