import familyService from '@/services/familyService';
import FamilyMember from '@/models/FamilyMember';
import { handleError, handleSuccess } from '@/utils/alertHandler';
import { Module, ActionContext } from 'vuex';
import {FamilyState, RootState} from "@/store/types";
import analyticsService from "@/services/analyticsService";

const state: FamilyState = {
    familyMembers: [],
    children: [],
    parents: [],
    error: null,
};

const getters = {
    allFamilyMembers: (state: FamilyState) => state.familyMembers,
    allChildren: (state: FamilyState) => state.children,
    allParents: (state: FamilyState) => state.parents,
    familyMemberById: (state: FamilyState) => (id: number) => state.familyMembers.find(member => member.id === id),
    error: (state: FamilyState) => state.error,
};

const mutations = {
    SET_FAMILY_MEMBERS(state: FamilyState, familyMembers: FamilyMember[]) {
        state.familyMembers = familyMembers;
    },
    SET_CHILDREN(state: FamilyState, familyMembers: FamilyMember[]) {
        state.children = familyMembers;
    },
    SET_PARENTS(state: FamilyState, familyMembers: FamilyMember[]) {
        state.parents = familyMembers;
    },
    ADD_FAMILY_MEMBER(state: FamilyState, familyMember: FamilyMember) {
        state.familyMembers.push(familyMember);
        if(familyMember.role === 'child')
            state.children.push(familyMember);
        else
            state.parents.push(familyMember);
    },
    UPDATE_FAMILY_MEMBER(state: FamilyState, updatedFamilyMember: FamilyMember) {
        const index = state.familyMembers.findIndex(member => member.id === updatedFamilyMember.id);
        if (index !== -1) {
            state.familyMembers.splice(index, 1, updatedFamilyMember);
        }
    },
    REMOVE_FAMILY_MEMBER(state: FamilyState, id: number) {
        state.familyMembers = state.familyMembers.filter(member => member.id !== id);
    },
    SET_ERROR(state: FamilyState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchFamilyMembers({ commit, dispatch }: ActionContext<FamilyState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchFamilyMember' }, { root: true });
        try {
            const data = await familyService.fetchAll();
            commit('SET_FAMILY_MEMBERS', data);
        } catch (error) {
            await handleError(error, true, { action: 'family/fetchFamilyMembers' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchFamilyMember' }, { root: true });
        }
    },

    async fetchChildren({ commit, dispatch }: ActionContext<FamilyState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchFamilyMember' }, { root: true });
        try {
            const data = await familyService.fetchChildren();
            commit('SET_CHILDREN', data);
        } catch (error) {
            await handleError(error, true, { action: 'family/fetchChildren' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchFamilyMember' }, { root: true });
        }
    },

    async fetchParents({ commit, dispatch }: ActionContext<FamilyState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchFamilyMember' }, { root: true });
        try {
            const data = await familyService.fetchParents();
            commit('SET_PARENTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'family/fetchParents' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchFamilyMember' }, { root: true });
        }
    },

    async createFamilyMember({ commit, dispatch }: ActionContext<FamilyState, unknown>, payload: Partial<FamilyMember>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateFamilyMember' }, { root: true });
        try {
            const data = await familyService.create(payload);
            commit('ADD_FAMILY_MEMBER', data);
            if(data.role === 'child')
                await dispatch('auth/addChildToUser', data, { root: true });
            else
                await dispatch('auth/addParentToUser', data, { root: true });
            await handleSuccess('family.memberCreated');
        } catch (error) {
            await handleError(error, true, { action: 'family/createFamilyMember', payload });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('family_created_member', {
                family: payload,
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateFamilyMember' }, { root: true });
        }
    },

    async updateFamilyMember({ commit, dispatch }: ActionContext<FamilyState, unknown>, payload: Partial<FamilyMember>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateFamilyMember' }, { root: true });
        try {
            const data = await familyService.update(payload);
            commit('UPDATE_FAMILY_MEMBER', data);
            await handleSuccess('family.memberUpdated');
        } catch (error) {
            await handleError(error, true, { action: 'family/updateFamilyMember', payload });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateFamilyMember' }, { root: true });
        }
    },

    async deleteFamilyMember({ commit, dispatch }: ActionContext<FamilyState, unknown>, id: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateFamilyMember' }, { root: true });
        try {
            await familyService.delete(id);
            commit('REMOVE_FAMILY_MEMBER', id);
            await handleSuccess('family.memberDeleted');
        } catch (error) {
            await handleError(error, true, { action: 'family/deleteFamilyMember', id });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateFamilyMember' }, { root: true });
        }
    },
    needFillParentData() {
        return true;
    }
};

const familyModule: Module<FamilyState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default familyModule;
