import Variety from "@/models/shop/Variety";
import ProductPath from "@/models/shop/ProductPath";
import ProductMilestone from "@/models/shop/ProductMilestone";


export default class Product {
    id: number;
    title: string;
    story: string;
    description: string;
    image_url: string;
    images: { image_url: string, alt_text: string }[];
    price: number;
    is_available: boolean;
    varieties: Variety[];
    productPaths: ProductPath[];
    productMilestones: ProductMilestone[];

    constructor({
                    id = 0,
                    title = '',
                    description = '',
                    story = '',
                    image_url = '',
                    images = [],
                    price = 0.0,
                    is_available = false,
                    varieties = [],
                    paths = [],
                    milestones = []
                } = {}) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.story = story;
        this.price = price;
        this.is_available = is_available;
        this.image_url = image_url;
        this.images = images;

        this.varieties = varieties.map(variety => new Variety(variety));
        this.productPaths = paths.map(productPath => new ProductPath(productPath));
        this.productMilestones = milestones.map(productMilestone => new ProductMilestone(productMilestone));
    }
}