import CartItem from "@/models/shop/CartItem";

export default class Cart {
    items: CartItem[];

    constructor({ items = [] } = {}) {
        this.items = items.map(item => new CartItem(item));
    }

    get total() {
        return this.items.reduce((total, item) => total + item.variety.price * item.quantity, 0);
    }
}
