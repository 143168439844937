import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import Product from '@/models/shop/Product';
import Cart from "@/models/shop/Cart";
import CartItem from "@/models/shop/CartItem";
import {PaymentMethod, PaymentResult} from "@/types/payment";

class CartData {
}

class ShopService {
    async fetchProduct(productId: number) {
        const { data } = await axiosInstance.get(`/api/shop/product/${productId}/`, { headers: authHeader() });
        return new Product(data);
    }

    async fetchCart() {
        const { data } = await axiosInstance.get('/api/shop/cart/', { headers: authHeader() });
        return new Cart(data);
    }

    async addToCart(varietyId: number, quantity: number) {
        const { data } = await axiosInstance.post(
            '/api/shop/cart/add/',
            { variety_id: varietyId, quantity },
            { headers: authHeader() }
        );
        return new CartItem(data);
    }

    async removeFromCart(varietyId: number): Promise<void> {
        await axiosInstance.post(
            '/api/shop/cart/remove/',
            { variety_id: varietyId },
            { headers: authHeader() }
        );
    }

    async updateCartItem(varietyId: number, quantity: number) {
        const { data } = await axiosInstance.post(
            '/api/shop/cart/update/',
            { variety_id: varietyId, quantity },
            { headers: authHeader() }
        );
        return new CartItem(data);
    }

    async clearCart() {
        await axiosInstance.post('/api/shop/cart/clear/', {}, { headers: authHeader() });
    }

    async addCartData(payload: Partial<CartData>) {
        const { data } = await axiosInstance.post('/api/shop/cart/data/create/', payload, { headers: authHeader() });
        return data;
    }

    async latestCartData() {
        const { data } = await axiosInstance.get('/api/shop/cart/data/latest/', { headers: authHeader() });
        return data;
    }

    async placeOrder(orderData: {
        address_id: number,
        payment_method: PaymentMethod,
        promo_code: null | string,
        [key: string]: any
    }): Promise<PaymentResult> {
        const { data } = await axiosInstance.post(
            '/api/shop/order/place/',
            orderData,
            { headers: authHeader() }
        );
        return data;
    }

    async checkOrderStatus(orderReference: string) {
        const { data } = await axiosInstance.get(
            `/api/shop/order/status/${orderReference}/`,
            { headers: authHeader() }
        );
        return data;
    }

    async applyPromoCode(promoCode: string) {
        const { data } = await axiosInstance.post(
            '/api/shop/order/apply-promo-code/',
            { promo_code: promoCode },
            { headers: authHeader() }
        );
        return parseFloat(data.discount);
    }

    async getPricingDetails(addressId: number, promoCode: string) {
        const { data } = await axiosInstance.post(
            '/api/shop/order/get-pricing-details/',
            { address_id: addressId, promo_code: promoCode },
            { headers: authHeader() }
        );
        return data;
    }
}

export default new ShopService();
