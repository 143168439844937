import Milestone from "@/models/Milestone";

export default class ProductMilestone {
    id: number;
    milestone: Milestone;
    percent: number;
    why: string;

    constructor({id = 0, milestone = {}, percent = 0, why = ''} = {}) {
        this.id = id;
        this.milestone = new Milestone(milestone);
        this.percent = percent;
        this.why = why;
    }
}