import { getUserCookie } from '@/utils/cookieUtils';

export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user') || '{}');

    if (!user.accessToken) {
        // Try to get user data from cookie
        const cookieUser = getUserCookie();
        if (cookieUser) {
            user = cookieUser;
            // Restore localStorage from cookie
            localStorage.setItem('user', JSON.stringify(cookieUser));
        }
    }

    if (user && user.accessToken) {
        return { Authorization: 'Token ' + user.accessToken };
    } else {
        return {};
    }
}
