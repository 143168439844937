import { Module } from 'vuex';
import {RootState, TurnFutureState} from "@/store/types";

const state: TurnFutureState = {
    error: null,
};

const getters = {
    
};

const mutations = {
};

const actions = {
    checkChandmahamePromotion() {
        return true;
    },
};

const turnFutureModule: Module<TurnFutureState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default turnFutureModule;
