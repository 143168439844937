import { Module, ActionContext } from 'vuex';
import AuthService from '@/services/authService';
import User from '@/models/User';
import {handleError, handleSuccess} from '@/utils/alertHandler';
import UserData from "@/models/UserData";
import {AuthState, RootState} from "@/store/types";
import analyticsService from '@/services/analyticsService'
import FamilyMember from "@/models/FamilyMember";
import {removeUserCookie, setUserCookie} from "@/utils/cookieUtils";

interface LatestUserDataResponse {
    [key: string]: string;
}


const state: AuthState = {
    user: null,
    isLoggedIn: false,
    mobile: null,
    isLoading: false,
    userData: {},
};

const mutations = {
    SET_USER(state: AuthState, user: User) {
        state.user = user;
        state.isLoggedIn = true;
    },
    LOGOUT(state: AuthState) {
        state.user = null;
        state.isLoggedIn = false;
    },
    SET_MOBILE(state: AuthState, mobile: string) {
        state.mobile = mobile;
    },
    ADD_USER_DATA(state: AuthState, data: UserData) {
        state.userData[data.data_key] = data.data_value;
    },
    ADD_CHILD_TO_USER(state: AuthState, child: FamilyMember) {
        if (state.user && state.user.children) {
            state.user.children.push(child);
        }
    },
    ADD_PARENT_TO_USER(state: AuthState, member: FamilyMember) {
        if (state.user && state.user.parents) {
            state.user.parents.push(member);
        }
    },
};

const actions = {
    async init({ commit, dispatch }: ActionContext<AuthState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'loadingScreen' }, { root: true });
        const userData = localStorage.getItem('user');

        if (userData) {
            const savedUser = JSON.parse(userData);
            try {
                const data = await AuthService.init();
                const registeredUser = new User({
                    id: data.user.id,
                    mobile: data.user.mobile,
                    accessToken: savedUser.accessToken,
                    first_name: data.user.first_name,
                    last_name: data.user.last_name,
                    children: data.user.children,
                    parents: data.user.parents,
                    usedServices: data.user.used_services
                });
                localStorage.setItem('user', JSON.stringify(registeredUser));
                setUserCookie(registeredUser);
                commit('SET_USER', registeredUser);
            } catch (error) {
                await handleError(error, true, {
                    action: 'auth/init',
                    savedUserId: savedUser.id,
                    errorContext: 'Failed to initialize user data'
                });
                localStorage.removeItem('user');
                commit('SET_USER', null);
            }
        } else {
            commit('SET_USER', null);
        }
        await dispatch('loader/setLoading', { isLoading: false, component: 'loadingScreen' }, { root: true });
    },
    async logout({ commit, dispatch }: ActionContext<AuthState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true }, { root: true });
        try {
            await AuthService.logout();
            localStorage.removeItem('user');
            removeUserCookie();
            commit('LOGOUT');
            await handleSuccess('user.logoutSuccess');
        } catch (error) {
            await handleError(error, true, { action: 'auth/logout' });
        } finally {
            analyticsService.track('auth_logout');
            await dispatch('loader/setLoading', { isLoading: false }, { root: true });
        }
    },
    async sendOtp({ commit, dispatch }: ActionContext<AuthState, unknown>, mobile: string) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'sendOtp' }, { root: true });
        try {
            await AuthService.sendOtp(mobile);
            commit('SET_MOBILE', mobile);
            await handleSuccess('verifyOtp.otpSent');
            return true;
        } catch (error) {
            await handleError(error, true, { action: 'auth/sendOtp', mobile });
            return false;
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'sendOtp' }, { root: true });
        }
    },
    async verifyOtp({ commit, dispatch }: ActionContext<AuthState, unknown>, { mobile, otp }: { mobile: string, otp: string }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'verifyOtp' }, { root: true });
        try {
            const data = await AuthService.verifyOtp(mobile, otp);
            const registeredUser = new User({
                id: data.user.id,
                mobile: data.user.mobile,
                accessToken: data.token,
                first_name: data.user.first_name,
                last_name: data.user.last_name,
                children: data.user.children,
                parents: data.user.parents
            });
            localStorage.setItem('user', JSON.stringify(registeredUser));
            setUserCookie(registeredUser);
            commit('SET_USER', registeredUser);
            await handleSuccess('verifyOtp.otpVerified');
            return true;
        } catch (error) {
            await handleError(error, true, { action: 'auth/verifyOtp', mobile, otp });
            return false;
        } finally {
            analyticsService.track('auth_login', {
                mobile
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'verifyOtp' }, { root: true });
        }
    },
    async updateUser({ commit, dispatch, state }: ActionContext<AuthState, RootState>, payload: Partial<User>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateUser' }, { root: true });
        try {
            const response = await AuthService.updateUser(payload);
            state.user?.update(response.user);
            localStorage.setItem('user', JSON.stringify(state.user));
            commit('SET_USER', state.user);
            await handleSuccess('user.userUpdated');
        } catch (error) {
            await handleError(error, true, { action: 'auth/updateUser', payload });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateUser' }, { root: true });
        }
    },
    async addUserData({ commit, dispatch, state }: ActionContext<AuthState, unknown>, payload: Partial<UserData>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateUser' }, { root: true });
        try {
            const response = await AuthService.addUserData(payload);
            commit('ADD_USER_DATA', response);
            await handleSuccess('user.userUpdated');
        } catch (error) {
            await handleError(error, true, { action: 'auth/addUserData', payload });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateUser' }, { root: true });
        }
    },
    async latestUserData({ commit, dispatch }: ActionContext<AuthState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true }, { root: true });
        try {
            const response = await AuthService.latestUserData();
            const userData = response as LatestUserDataResponse;
            for (const [data_key, data_value] of Object.entries(userData)) {
                commit('ADD_USER_DATA', new UserData({ data_key, data_value }));
            }
        } catch (error) {
            await handleError(error, true, { action: 'auth/latestUserData' });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false }, { root: true });
        }
    },
    async needFillExtraData({ state, dispatch }: ActionContext<AuthState, unknown>, dataKeys: string[]): Promise<string[]> {
        await dispatch('latestUserData'); // Ensure we fetch the latest user data
        const missingKeys: string[] = [];

        for (const key of dataKeys) {
            if (!(key in state.userData)) {
                missingKeys.push(key);
            }
        }

        return missingKeys;
    },
    addChildToUser({ commit }: ActionContext<AuthState, RootState>, child: FamilyMember) {
        commit('ADD_CHILD_TO_USER', child);
    },
    addParentToUser({ commit }: ActionContext<AuthState, RootState>, member: FamilyMember) {
        commit('ADD_PARENT_TO_USER', member);
    },
};

const getters = {
    isLoggedIn: (state: AuthState) => state.isLoggedIn,
    user: (state: AuthState) => state.user,
    mobile: (state: AuthState) => state.user?.mobile,
    isLoading: (state: AuthState) => state.isLoading,
    allUserData: (state: AuthState) => state.userData,
};

const authModule: Module<AuthState, RootState> = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default authModule;
