import ProductOption from "@/models/shop/ProductOption";
import Product from "@/models/shop/Product";

export default class Variety {
    id: number;
    title: string;
    price: number;
    is_available: boolean;
    description: string;
    option: ProductOption | null;
    value: string | null;
    product: Product | null;

    constructor({id = 0, title = '', price = 0.0, is_available = false, description = '', option = null, value = null,  product = null} = {}) {
        this.id = id;
        this.title = title;
        this.price = price;
        this.is_available = is_available;
        this.description = description;
        this.option = option ? new ProductOption(option) : null;
        this.value = value;
        this.product = product ? new Product(product) : null;
    }
}