import { ActionContext, Module } from 'vuex';
import {RootState, AIServiceState} from '@/store/types';
import { handleError } from '@/utils/alertHandler';
import aiServiceService from '@/services/aiServicesService';

const state: AIServiceState = {
    error: null,
    futureImage: '',
    empatheticMessage: '',
    suggestedChallenges: [],
    memberFeatures: null,
};

const getters = {
    theFutureImage: (state: AIServiceState) => state.futureImage,
    theMemberFeatures: (state: AIServiceState) => state.memberFeatures,
    theEmpatheticMessage: (state: AIServiceState) => state.empatheticMessage,
    allSuggestedChallenges: (state: AIServiceState) => state.suggestedChallenges,
};

const mutations = {
    SET_SUGGESTED_CHALLENGES(state: AIServiceState, challenges: string[]) {
        state.suggestedChallenges = challenges;
    },
    SET_EMPATHETIC_MESSAGE(state: AIServiceState, message: string) {
        state.empatheticMessage = message;
    },
    SET_FUTURE_IMAGE(state: AIServiceState, image_url: string) {
        state.futureImage = image_url;
    },
    SET_MEMBER_FEATURES(state: AIServiceState, features: Record<string, any>) {
        state.memberFeatures = features;
    },
    SET_ERROR(state: AIServiceState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchEmpatheticMessage({ commit, dispatch }: ActionContext<AIServiceState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchEmpatheticMessage' }, { root: true });
        try {
            const data = await aiServiceService.fetchEmpatheticMessage();
            commit('SET_EMPATHETIC_MESSAGE', data.message);
            commit('SET_SUGGESTED_CHALLENGES', data.challenges);
        } catch (error) {
            await handleError(error, true, { action: 'aiServices/fetchEmpatheticMessage' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchEmpatheticMessage' }, { root: true });
        }
    },
    async fetchFutureImage({ commit, dispatch }: ActionContext<AIServiceState, unknown>, { memberId }: { memberId: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchFutureImage' }, { root: true });
        try {
            const data = await aiServiceService.fetchFutureImage(memberId);
            commit('SET_FUTURE_IMAGE', data.url);
            commit('SET_MEMBER_FEATURES', data.features);
        } catch (error) {
            await handleError(error, true, { action: 'aiServices/fetchFutureImage' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchFutureImage' }, { root: true });
        }
    },
};

const aiServicesModule: Module<AIServiceState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default aiServicesModule;
