import { ActionContext, Module } from 'vuex';
import {RootState, ShopState, UserStoryState} from '@/store/types';
import UserStory from '@/models/UserStory';
import { handleError } from '@/utils/alertHandler';
import userStoryService from "@/services/userStoryService";
import analyticsService from "@/services/analyticsService";

const state: UserStoryState = {
    error: null,
    userStories: [],
    loadedUserStory: new UserStory(),
};

const getters = {
    allUserStories: (state: UserStoryState) => state.userStories,
    loadedUserStory: (state: UserStoryState) => state.loadedUserStory,
};

const mutations = {
    SET_LOADED_STORY(state: UserStoryState, userStory: UserStory) {
        state.loadedUserStory = userStory;
    },
    ADD_USER_STORY(state: UserStoryState, userStory: UserStory) {
        state.userStories.push(userStory);
        state.loadedUserStory = userStory
    },
    SET_USER_STORIES(state: UserStoryState, userStories: UserStory[]) {
        state.userStories = userStories;
    },
    SET_ERROR(state: UserStoryState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchUserStories({ commit, dispatch }: ActionContext<UserStoryState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchUserStories' }, { root: true });
        try {
            const userStories = await userStoryService.fetchUserStories();
            commit('SET_USER_STORIES', userStories);
        } catch (error) {
            await handleError(error, true, { action: 'userStory/fetchUserStories' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchUserStories' }, { root: true });
        }
    },
    async addUserStory({ commit, dispatch }: ActionContext<UserStoryState, unknown>, { query }: { query: string }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'addUserStory' }, { root: true });
        try {
            const userStory = await userStoryService.addUserStory(query);
            commit('ADD_USER_STORY', userStory);
        } catch (error) {
            await handleError(error, true, { action: 'userStory/addUserStory' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            analyticsService.track('userStory_query_submitted', {
                query
            });
            await dispatch('loader/setLoading', { isLoading: false, component: 'addUserStory' }, { root: true });
        }
    },
    async fetchUserStory({ commit, dispatch }: ActionContext<UserStoryState, unknown>, { userStoryId }: { userStoryId: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchUserStory' }, { root: true });
        try {
            const userStory = await userStoryService.fetchUserStory(userStoryId);
            commit('SET_LOADED_STORY', userStory);
        } catch (error) {
            await handleError(error, true, { action: 'userStory/fetchUserStory', userStoryId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchUserStory' }, { root: true });
        }
    },
};

const userStoryModule: Module<UserStoryState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default userStoryModule;
