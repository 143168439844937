import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import FamilyMember from "@/models/FamilyMember";

class familyService {
    async fetchAll() {
        const { data } = await axiosInstance.get('/api/family/members/list/', { headers: authHeader() });
        return data;
    }

    async fetchChildren() {
        const { data } = await axiosInstance.get('/api/family/children/', { headers: authHeader() });
        return data;
    }

    async fetchParents() {
        const { data } = await axiosInstance.get('/api/family/parents/', { headers: authHeader() });
        return data;
    }

    async create(payload: Partial<FamilyMember>) {
        const { data } = await axiosInstance.post('/api/family/members/', payload, { headers: authHeader() });
        return new FamilyMember(data);
    }

    async update(payload: Partial<FamilyMember>) {
        const { data } = await axiosInstance.put(`/api/family/members/${payload.id}/`, payload, { headers: authHeader() });
        return data;
    }

    async delete(id: number) {
        await axiosInstance.delete(`/api/family/members/${id}`, { headers: authHeader() });
    }
}

export default new familyService();
