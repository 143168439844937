import { ActionContext, Module } from 'vuex';
import { RootState, JourneyState } from '@/store/types';
import Path from '@/models/Path';
import { handleError, handleSuccess } from '@/utils/alertHandler';
import journeyService from '@/services/journeyService';

const state: JourneyState = {
    error: null,
    paths: [],
};

const getters = {
    allPaths: (state: JourneyState) => state.paths,
};

const mutations = {
    SET_PATHS(state: JourneyState, paths: Path[]) {
        state.paths = paths;
    },
    SET_ERROR(state: JourneyState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchPaths({ commit, dispatch }: ActionContext<JourneyState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchPaths' }, { root: true });
        try {
            const data = await journeyService.fetchPaths();
            commit('SET_PATHS', data);
        } catch (error) {
            await handleError(error, true, { action: 'journey/fetchPaths' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchPaths' }, { root: true });
        }
    }
};

const recommendationModule: Module<JourneyState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default recommendationModule;
