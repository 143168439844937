import {ActionContext, Module} from 'vuex';
import {TelegramState, RootState} from "@/store/types";
import {handleError, handleSuccess} from "@/utils/alertHandler";
import telegramService from "@/services/telegramService";

const state: TelegramState = {
    error: null,
};


const actions = {
    async userVerification({ commit, dispatch }: ActionContext<TelegramState, RootState>, { token }: { token: string }) {
        await dispatch('loader/setLoading', { isLoading: true }, { root: true });
        try {
            const verificationResult = await telegramService.userVerification(token);
            await handleSuccess('telegram.verificationDone');
            return verificationResult;
        } catch (error) {
            await handleError(error, true, { action: 'telegram/userVerification' });
        } finally {
            await dispatch('loader/setLoading', { isLoading: false }, { root: true });
        }
    },
};

const telegramModule: Module<TelegramState, RootState> = {
    namespaced: true,
    actions,
};

export default telegramModule;