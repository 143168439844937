import axiosInstance from './axiosInstance';
import authHeader from './authHeader';

class TelegramService {
    async userVerification(token: string) {
        const { data } = await axiosInstance.get(`api/telegram/verify/${token}/`, { headers: authHeader() });
        return data;
    }
}

export default new TelegramService();
