import { Address } from '@/models/Address';
import { State } from '@/models/State';
import { City } from '@/models/City';
import { handleError, handleSuccess } from '@/utils/alertHandler';
import { Module, ActionContext } from 'vuex';
import { AddressState, RootState } from "@/store/types";
import addressService from "@/services/AddressService";

const state: AddressState = {
    addresses: [],
    states: [],
    cities: [],
    error: null,
};

const getters = {
    allAddresses: (state: AddressState) => state.addresses,
    allStates: (state: AddressState) => state.states,
    allCities: (state: AddressState) => state.cities,
    addressById: (state: AddressState) => (id: number) => state.addresses.find(address => address.id === id),
    error: (state: AddressState) => state.error,
};

const mutations = {
    SET_ADDRESSES(state: AddressState, addresses: Address[]) {
        state.addresses = addresses;
    },
    SET_STATES(state: AddressState, states: State[]) {
        state.states = states;
    },
    SET_CITIES(state: AddressState, cities: City[]) {
        state.cities = cities;
    },
    ADD_ADDRESS(state: AddressState, address: Address) {
        state.addresses.push(address);
    },
    UPDATE_ADDRESS(state: AddressState, updatedAddress: Address) {
        const index = state.addresses.findIndex(address => address.id === updatedAddress.id);
        if (index !== -1) {
            state.addresses.splice(index, 1, updatedAddress);
        }
    },
    REMOVE_ADDRESS(state: AddressState, id: number) {
        state.addresses = state.addresses.filter(address => address.id !== id);
    },
    SET_ERROR(state: AddressState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchAddresses({ commit, dispatch }: ActionContext<AddressState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchAddresses' }, { root: true });
        try {
            const data = await addressService.fetchAddresses();
            commit('SET_ADDRESSES', data);
        } catch (error) {
            await handleError(error, true, { action: 'address/fetchAddresses' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchAddresses' }, { root: true });
        }
    },

    async fetchStates({ commit, dispatch }: ActionContext<AddressState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchStates' }, { root: true });
        try {
            const data = await addressService.fetchStates();
            commit('SET_STATES', data);
        } catch (error) {
            await handleError(error, true, { action: 'address/fetchStates' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchStates' }, { root: true });
        }
    },

    async fetchCities({ commit, dispatch }: ActionContext<AddressState, unknown>, stateId: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchCities' }, { root: true });
        try {
            const data = await addressService.fetchCities(stateId);
            commit('SET_CITIES', data);
        } catch (error) {
            await handleError(error, true, { action: 'address/fetchCities' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchCities' }, { root: true });
        }
    },

    async createAddress({ commit, dispatch }: ActionContext<AddressState, unknown>, payload: Partial<Address>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateAddress' }, { root: true });
        try {
            const data = await addressService.createAddress(payload);
            commit('ADD_ADDRESS', data);
            await handleSuccess('address.addressCreated');
        } catch (error) {
            await handleError(error, true, { action: 'address/createAddress', payload });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateAddress' }, { root: true });
        }
    },

    async updateAddress({ commit, dispatch }: ActionContext<AddressState, unknown>, payload: Partial<Address>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'updateAddress' }, { root: true });
        try {
            const data = await addressService.updateAddress(payload);
            commit('UPDATE_ADDRESS', data);
            await handleSuccess('address.addressUpdated');
        } catch (error) {
            await handleError(error, true, { action: 'address/updateAddress', payload });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'updateAddress' }, { root: true });
        }
    },

    async deleteAddress({ commit, dispatch }: ActionContext<AddressState, unknown>, id: number) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchAddresses' }, { root: true });
        try {
            await addressService.deleteAddress(id);
            commit('REMOVE_ADDRESS', id);
            await handleSuccess('address.addressDeleted');
        } catch (error) {
            await handleError(error, true, { action: 'address/deleteAddress', id });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchAddresses' }, { root: true });
        }
    },
};

const addressModule: Module<AddressState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default addressModule;
