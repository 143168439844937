export default class ProductCategory {
    id: number;
    title: string;
    slug: string;
    parent: number | null;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.parent = data.parent;
    }
}
