import Cookies from 'js-cookie';

const isProduction = process.env.NODE_ENV === 'production';

export const setCookie = (name: string, value: any, options: Cookies.CookieAttributes = {}) => {
    const defaultOptions: Cookies.CookieAttributes = {
        secure: true,
        sameSite: isProduction ? 'strict' : 'lax',
        ...options
    };

    Cookies.set(name, JSON.stringify(value), defaultOptions);
};

export const getCookie = (name: string) => {
    const cookieValue = Cookies.get(name);
    return cookieValue ? JSON.parse(cookieValue) : null;
};

export const removeCookie = (name: string) => {
    Cookies.remove(name);
};

export const setUserCookie = (user: any) => {
    setCookie('user', user);
};

export const getUserCookie = () => {
    return getCookie('user');
};

export const removeUserCookie = () => {
    removeCookie('user');
};