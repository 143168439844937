import { inject, provide } from 'vue';
import { App } from 'vue';

const analyticsSymbol = Symbol('analytics');

export const useAnalytics = (): AnalyticsService => {
    const analytics = inject<AnalyticsService>(analyticsSymbol);
    if (!analytics) {
        throw new Error('Analytics service not provided');
    }
    return analytics;
};

export class AnalyticsService {
    track(eventName: string, parameters?: Record<string, any>) {
        // Log to console in development
        if (process.env.NODE_ENV === 'development') {
            console.log('Analytics Event:', eventName, parameters);
        }

        // Send to GA4
        if (window.gtag) {
            window.gtag('event', eventName, parameters);
        }

        // Here you can add calls to other analytics services if needed
    }

    trackError(error: unknown, additionalInfo?: any) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        this.track('error', {
            event_category: 'exception',
            event_label: errorMessage,
            additional_info: additionalInfo,
        });
    }
}

export const createAnalyticsPlugin = () => ({
    install(app: App) {
        const analytics = new AnalyticsService();
        app.provide(analyticsSymbol, analytics);
    },
});

export default new AnalyticsService()

declare global {
    interface Window {
        gtag: (...args: any[]) => void;
    }
}
