// Address.ts
import { City } from './City';
import { State } from './State';

export class Address {
    id: number;
    name: string;
    mobile: string;
    phone_number: string;
    state: State | null;
    city: City | null;
    state_id: number | null;
    city_id: number | null;
    address: string;
    postal_code: string;
    isDefault: boolean;

    constructor(
        id: number,
        name: string,
        mobile: string,
        phone_number: string,
        state: State | null = null,
        city: City | null = null,
        address: string,
        postal_code: string,
        isDefault: boolean
    ) {
        this.id = id;
        this.name = name;
        this.mobile = mobile;
        this.phone_number = phone_number;
        this.state = state;
        this.city = city;
        this.state_id = state ? state.id : null;
        this.city_id = city ? city.id : null;
        this.address = address;
        this.postal_code = postal_code;
        this.isDefault = isDefault;
    }
}
