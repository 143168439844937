<template>
  <div class="min-h-screen bg-background relative flex flex-col">
    <!-- Content area -->
    <div class="flex-grow">
      <slot></slot>
    </div>

    <!-- Curved background shape -->
    <div class="w-full">
      <svg
          class="w-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
      >
        <path
            fill="#FFDAB9"
            fill-opacity="0.5"
            d="M0,96 C360,192,1080,32,1440,128 L1440,320 L0,320 Z"
        ></path>
        <path
            fill="#B8E0D2"
            fill-opacity="1"
            d="M0,160 C320,300,1120,0,1440,160 L1440,320 L0,320 Z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurvedBackground"
}
</script>

<style scoped>
/* Your existing styles */
</style>