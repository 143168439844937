import { APP_LOCALE } from "@/config";
import moment from 'moment-jalaali';
import { DirectiveBinding, ObjectDirective } from 'vue';

interface FormattedDateElement extends HTMLElement {
    _watcher?: () => void;
}

export const formatDate: ObjectDirective<FormattedDateElement, string> = {
    mounted(el: FormattedDateElement, binding: DirectiveBinding<string>) {
        const formatDateValue = (value: string | undefined) => {
            if (!value) {
                el.textContent = '';
                return;
            }

            if (APP_LOCALE === 'fa') {
                el.textContent = moment(value).format('jYYYY/jMM/jDD');
            } else {
                el.textContent = moment(value).format('YYYY-MM-DD');
            }
        };

        formatDateValue(binding.value);

        // If you want the directive to be reactive to changes in the bound value
        if (binding.instance) {
            el._watcher = binding.instance.$watch(() => binding.value, formatDateValue);
        }
    },
    updated(el: FormattedDateElement, binding: DirectiveBinding<string>) {
        if (!binding.value) {
            el.textContent = '';
            return;
        }

        if (APP_LOCALE === 'fa') {
            el.textContent = moment(binding.value).format('jYYYY/jMM/jDD');
        } else {
            el.textContent = moment(binding.value).format('YYYY-MM-DD');
        }
    },
    unmounted(el: FormattedDateElement) {
        // Clean up the watcher when the element is unmounted
        if (el._watcher) {
            el._watcher();
        }
    }
};