import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import Path from "@/models/Path";

class JourneyService {
    async fetchPaths() {
        const { data } = await axiosInstance.get('/api/journey/paths/', { headers: authHeader() });
        return data.map((item: any) => new Path(item));
    }
}

export default new JourneyService();
