import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import Product from '@/models/shop/Product';
import UserStory from "@/models/UserStory";
import ProductCategory from "@/models/shop/ProductCategory";

class RecommendationService {
    async fetchFamilyRecommendation() {
        const { data } = await axiosInstance.get('/api/recommendation/room/', { headers: authHeader() });
        return data;
    }

    async fetchPathProducts(pathKey: string) {
        const { data } = await axiosInstance.get(`/api/recommendation/path/${pathKey}/`, { headers: authHeader() });
        return data.map((item: any) => new Product(item));
    }

    async fetchUserStoryProducts(storyId: number) {
        const { data } = await axiosInstance.get(`/api/recommendation/story/${storyId}/`, { headers: authHeader() });
        return data.map((item: any) => new Product(item));
    }

    async fetchUserStoryCategories(storyId: number) {
        const { data } = await axiosInstance.get(`/api/recommendation/story/${storyId}/categories/`, { headers: authHeader() });
        // Map the categories and their products
        return data.map((item: any) => {
            const category = new ProductCategory(item.category);
            const products = item.products.map((productData: any) => new Product(productData));
            return { category, products };
        });
    }

    async fetchUserStoryTopProducts(storyId: number) {
        const { data } = await axiosInstance.get(`/api/recommendation/story/${storyId}/top/`, { headers: authHeader() });
        return data.items.map((item: any) => ({
            rank: item.r,
            product: new Product(item.product),
            confidence: item.c,
            persuasiveText: item.w,
            ageRange: item.a
        }));
    }

    async createUserStory(payload: Partial<UserStory>) {
        const { data } = await axiosInstance.post('/api/userstory/stories/', payload, { headers: authHeader() });
        return data;
    }
}

export default new RecommendationService();
