import Variety from "@/models/shop/Variety";

export default class CartItem {
    variety: Variety;
    quantity: number;

    constructor({ variety = {}, quantity = 1 } = {}) {
        this.variety = new Variety(variety);
        this.quantity = quantity;
    }
}
